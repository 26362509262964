import EventWebPageData from './EventWebPageData';
import EventWebPagesMetaDataViewModel from './EventWebPagesMetaDataViewModel';
import EventWebPageThemes from './EventWebPageThemes';
export default class EventWebPageViewModel {
  public static createEmpty(): EventWebPageViewModel {
    return new EventWebPageViewModel();
  }
  constructor(
    public Id = 0,
    public AppId = 0,
    public AppEncodedName = '',
    public EventId = 0,
    public EventName = '',
    public IsEventWebPageEnabled = false,
    public IsEventWebPagePFActive = false,
    public InviteUrl = '',
    public IsInviteePublished = false,
    public EventWebPageSupportedFonts: string[] = [],
    public EventWebPageThemeSettings: EventWebPageThemes = EventWebPageThemes.createEmpty(),
    public EventWebPages: EventWebPageData = EventWebPageData.createEmpty(),
    public EventWebPagesMetaData: EventWebPagesMetaDataViewModel = EventWebPagesMetaDataViewModel.createEmpty(),
  ){}
}
