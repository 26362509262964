export default class EventWebPageThemes {
  public static createEmpty(): EventWebPageThemes {
    return new EventWebPageThemes();
  }
  constructor(
    public Id = 0,
    public PrimaryBackgroundColor = '',
    public PrimaryFontColor = '',
    public HeaderFooterBackgroundColor = '',
    public HeaderFooterFontColor = '',
    public AlwaysShowHeaderBackground = false,
    public ButtonAccentBackgroundColor = '',
    public ButtonAccentFontColor = '',
    public LinkFontColor = '',
    public FontFamily = '',
    public FontAppearance = '',
  ){}
}
