
import Common from '@/helper/Common';
import { Component, Vue, toNative, Prop} from 'vue-facing-decorator';
import { DetailModalPopupViewModel } from '@/entities/DetailModalPopupViewModel';
import EventWebPageHelper from '@/helper/EventWebPageHelper';
import { FetchData } from '@/main';
@Component
class DetailModalPopup extends Vue {
  @Prop() private detailModalPopupData!: DetailModalPopupViewModel;
  private copiedIcon: string = require('@/assets/Images/link_icon_small.svg');
  private localDetailModalPopupData: DetailModalPopupViewModel = DetailModalPopupViewModel.createEmpty();
  private showCopiedMessage = false;
  private linkCopiedFadeoutTime = 500;
  private isPathValid = true;
  private isGoogleTagIdNull = false;
  private maxPathLength = EventWebPageHelper.MaxPathLength;
  private urlGenerated = false;
  private enableGoogleTagManager = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private mounted() {
    this.localDetailModalPopupData = Object.assign({},this.detailModalPopupData);
    this.enableGoogleTagManager = this.localDetailModalPopupData.GoogleTagId !== null && this.localDetailModalPopupData.GoogleTagId !== '' ? true : false;
  }
  private CopyPublicLinkToClipboard() {
    this.showCopiedMessage = Common.copyPublicLinkToClipboard(
      this.GetLink(),
    );
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
  }
  private GetLink() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      return this.localDetailModalPopupData.StaticPath + this.localDetailModalPopupData.AppEncodedName + '/' + this.localDetailModalPopupData.EncodedPath;
    } else {
      return '';
    }
  }
  private GetPath() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      return this.localDetailModalPopupData.StaticPath + this.localDetailModalPopupData.AppEncodedName + '/<b>' + this.localDetailModalPopupData.EncodedPath + '</b>';
    }
  }
  private ShowWarningMessage() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      return !this.isPathValid || this.isGoogleTagIdNull;
    }
  }
  private GetWarningMessage() {
    if(!this.isPathValid && this.isGoogleTagIdNull) {
      return this.$t('EventWebpage.EnterGoogleTagId').toString() + ' ; ' + this.localDetailModalPopupData.InvalidUrlMessage;
    } else if(!this.isPathValid) {
      return this.localDetailModalPopupData.InvalidUrlMessage;
    } else {
      return this.$t('EventWebpage.EnterGoogleTagId').toString();
    }
  }
  private ValidateData() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      this.isPathValid = !this.localDetailModalPopupData.EventsPath.includes(this.localDetailModalPopupData.Path.toLowerCase());
    }
    if(this.localDetailModalPopupData.Path === '') {
      this.isPathValid = false;
      this.localDetailModalPopupData.InvalidUrlMessage = this.$t('EventWebpage.EmptyUrl').toString();
    } else {
      this.localDetailModalPopupData.InvalidUrlMessage = this.detailModalPopupData.InvalidUrlMessage;
    }
    if((this.localDetailModalPopupData.GoogleTagId === null || this.localDetailModalPopupData.GoogleTagId === '') && this.enableGoogleTagManager) {
      this.isGoogleTagIdNull = true;
    } else {
      this.isGoogleTagIdNull = false;
    }
  }
  private Cancel(): void {
    // close modal pop up and emit to parent component
    this.$emit('close-popup');
  }
  private Save() {
    // save modal pop up and emit to parent component
    this.ValidateData();
    if(this.isPathValid && !this.isGoogleTagIdNull) {
      this.localDetailModalPopupData.GoogleTagId = this.enableGoogleTagManager ? this.localDetailModalPopupData.GoogleTagId : null;
      this.$emit('publish', this.localDetailModalPopupData);
    }
  }

  private GetEncodedPath() {
    this.urlGenerated = true;
    this.localDetailModalPopupData.Path = this.localDetailModalPopupData.Path.replace('+', '').replace('/', '').replace('\\', '');
    FetchData('/Common/GetEncodedPath', JSON.stringify({path: this.localDetailModalPopupData.Path}))
      .then((response) => {
        this.localDetailModalPopupData.EncodedPath = response;
        this.urlGenerated = false;
      })
      .catch(() => {
        // Do nothing
      });
  }
}
export default toNative(DetailModalPopup);
