export default class EventWebPagesMetaDataViewModel {
  public static createEmpty(): EventWebPagesMetaDataViewModel {
    return new EventWebPagesMetaDataViewModel();
  }
  constructor(
    public Id = 0,
    public LogoURL = '',
    public CTAButtonType = 0,
    public CTAButtonTitle = '',
    public CTAButtonURL = '',
    public BannerImageURL = '',
    public BannerText = '',
    public StartDate = new Date(),
    public EndDate = new Date(),
    public BannerVenueName = '',
    public HideDates = false,
    public ShowAboutSection = false,
    public AboutSectionTitle = '',
    public AboutSectionDescription = '',
    public ShowScheduleSection = false,
    public ScheduleSectionTitle = '',
    public ScheduleSectionShowEndTime = false,
    public ScheduleSectionShowDescription = false,
    public ScheduleSectionShowTracks = false,
    public ScheduleSectionShowTags = false,
    public ScheduleSectionShowProgramSpeakers = false,
    public ShowSpeakers = false,
    public SpeakerSectionTitle = '',
    public ShowVenue = false,
    public VenueSectionTitle = '',
    public Address = '',
    public City = '',
    public Latitude = 0,
    public Longitude = 0,
    public ShowSponsors = false,
    public SponsorSectionTitle = '',
    public ShowOrganizer = false,
    public OrganizerSectionTitle = '',
    public OrganizerImageUrl = '',
    public OrganizerDescription = '',
    public ShowFooter = false,
    public FooterText = '',
    public TemplateName = '',
    public EventWebPageThemeSettingsId = 0,
    public UpdatedOn = new Date(),
    public ShowOverlayOnBannerImage = 0,
  ) {}
}
