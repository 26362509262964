
import { Component, Vue, toNative, Prop } from 'vue-facing-decorator';
import AnalyticsModalPopupViewModel from '@/entities/AnalyticsModalPopupViewModel';

@Component
class AnalyticsModalPopup extends Vue {
  @Prop() analyticsModalPopUp!: AnalyticsModalPopupViewModel;
  private localAnalyticsModalPopUp: AnalyticsModalPopupViewModel = AnalyticsModalPopupViewModel.createEmpty();
  private mounted() {
    this.localAnalyticsModalPopUp = Object.assign({}, this.analyticsModalPopUp);
  }
  private Cancel(): void {
    // close modal pop up and emit to parent component
    this.$emit('close');
  }
}
export default toNative(AnalyticsModalPopup);
