export class DetailModalPopupViewModel {
  public static createEmpty(): DetailModalPopupViewModel {
    return new DetailModalPopupViewModel();
  }
  constructor(
    public Heading: string = '',
    public SubHeading1: string = '',
    public SubHeading2: string = '',
    public AppEncodedName: string = '',
    public Path: string = '',
    public EncodedPath: string = '',
    public StaticPath: string = '',
    public EventsPath: string[] = [],
    public InvalidUrlMessage: string = '',
    public HidePageFromSearchEngines: boolean = false,
    public HidePageFromSearchEnginesLabel: string = '',
    public Button1Text: string = '',
    public Button2Text: string = '',
    public GoogleTagId: string | null = null,
  ) { }
}
