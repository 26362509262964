
import { Component, Vue, toNative } from 'vue-facing-decorator';
import EventWebpageEditPane from '@/components/EventWebpage/EventWebpageEditPane.vue';
@Component({
  components: {
    EventWebpageEditPane,
  },
})
class EventWebPage extends Vue {
}
export default toNative(EventWebPage);
