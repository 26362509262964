import AnalyticsModalPopupDataViewModel from './AnalyticsModalPopupDataViewModel';
export default class AnalyticsModalPopupViewModel {
  public static createEmpty(): AnalyticsModalPopupViewModel {
    return new AnalyticsModalPopupViewModel();
  }
  constructor(
    public Heading = '',
    public AnalyticsData: AnalyticsModalPopupDataViewModel[] = [],
  ){}
}
