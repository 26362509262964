
import { Component, Vue, toNative, Watch, Prop } from 'vue-facing-decorator';
@Component({
  components: {
  },
})
class AddURL extends Vue {
  @Prop()
  private itemValue?: string;
  @Prop()
  // Local variables
  private placeholderText?: string;
  @Prop()
  private isRequired?: boolean;
  @Prop() private isDisabled?: boolean;
  private localUrl = '';
  private localPlaceholder = '';
  // regex expression for validate url
  private regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)(?:\.(?:[aA-zZ\u00a1-\uffff0-9]-*)*[aA-zZ\u00a1-\uffff0-9]+)*(?:\.(?:[aA-zZ\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  // Fill local variables
  private mounted() {
    this.localUrl = this.itemValue!;
    this.ValidateURL();
    this.localPlaceholder = this.placeholderText!;
  }
  // Validate the Url
  private ValidateURL() {
    if((this.localUrl === '' || this.localUrl === null) && !this.isRequired) {
      this.$emit('is-valid-url', true);
    } else if (!this.IsValidURL(this.localUrl)) {
      this.$emit('is-valid-url', false);
    } else {
      this.$emit('is-valid-url', true);
    }
    this.$emit('url', this.localUrl);
  }
  // Validate the Url string usign regex
  private IsValidURL(str: string): boolean {
    const regexp = this.regex;
    const result = regexp.test(str);
    return result;
  }
  // Watch on itemValue change and update local variable
  @Watch('itemValue')
  private onItemValueChanged(
    value: string,
  ) {
    this.localUrl = value!;
    this.ValidateURL();
  }
  // Watch on placeholderText change and update local variable
  @Watch('placeholderText')
  private onPlaceholderTextChanged(
    value: string,
  ) {
    this.localPlaceholder = value!;
  }
  @Watch('isRequired')
  private CheckChangeInIsRequired() {
    this.ValidateURL();
  }
}
export default toNative(AddURL);
