import { Vue } from 'vue-facing-decorator';

export default class EventWebPageHelper extends Vue{
  public static LogoMaxSize = 2;
  public static BackgroundImageMaxSize = 5;
  public static LogoMinHeight = 60;
  public static BackgroundImageMaxHeight = 700;
  public static BackgroundImageMaxWidth = 1600;
  public static BackgroundImageWidthRatio = 16;
  public static BackgroundImageHeightRatio = 7;
  public static ToasterTimeOut = 3000;
  public static MaxTitleLength = 20;
  public static maxInputFieldLength = 120;
  public static MaxNavBarItems = 4;
  public static FontAppearance = ['Smaller','Normal','Larger'];
  public static LinkCopiedFadeoutTime = 1500;
  public static MaxOriginToBeDisplayed = 5;
  public static MaxPathLength = 50;
}
