import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      id: "inputURL",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localUrl) = $event)),
      type: "url",
      placeholder: _ctx.localPlaceholder,
      disabled: _ctx.isDisabled,
      class: "form-control",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ValidateURL()))
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.localUrl]
    ])
  ]))
}